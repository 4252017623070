import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';

const ProcessoForm = () => {
  const [anoVeiculo, setAnoVeiculo] = useState('');
  const [processo, setProcesso] = useState(null);
  const [valorEntrada, setValorEntrada] = useState('');
  const [parcelasPagas, setParcelasPagas] = useState('');
  const [valorParcela, setValorParcela] = useState('');
  const [documentacao, setDocumentacao] = useState('');
  const [quantidadeAtraso, setQuantidadeAtraso] = useState('');
  const [fipe, setFipe] = useState('');
  const [banco, setBanco] = useState('');
  const [mensagem, setMensagem] = useState('');

  const toast = useToast();

  const buscarProcesso = async () => {
    try {
      const response = await axios.post('https://2app.wh.tec.br/buscar-processo', { ano_veiculo: anoVeiculo });
      if (response.data) {
        const processo = response.data; // Ajustado para pegar o processo diretamente
        setProcesso(processo);
        setValorEntrada(processo.valor_entrada || '');
        setParcelasPagas(processo.parcelas_pagas || '');
        setValorParcela(processo.valor_parcela || '');
        setDocumentacao(processo.documentacao || '');
        setQuantidadeAtraso(processo.quantidade_atraso || '');
        setFipe(processo.fipe || '');
        setBanco(processo.banco || '');
        setMensagem('');
        toast({
          title: "Processo encontrado.",
          description: `Processo ${processo.processo} carregado com sucesso.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setMensagem('Nenhum processo encontrado para o ano do veículo especificado.');
        setProcesso(null);
      }
    } catch (error) {
      setMensagem('Erro ao buscar processo. Tente novamente.');
      toast({
        title: "Erro.",
        description: "Erro ao buscar processo. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const atualizarProcesso = async () => {
    try {
      await axios.post('https://2app.wh.tec.br/atualizar-processo', {
        ano_veiculo: anoVeiculo,
        processo: processo.processo, // Usando o número do processo para atualização
        valor_entrada: valorEntrada,
        parcelas_pagas: parcelasPagas,
        valor_parcela: valorParcela,
        documentacao: documentacao,
        quantidade_atraso: quantidadeAtraso,
        fipe: fipe,
        banco: banco,
      });
      setMensagem('Processo atualizado com sucesso!');
      toast({
        title: "Processo atualizado.",
        description: "O processo foi atualizado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      buscarProcesso(); // Buscar o próximo processo (se houver) após a atualização
    } catch (error) {
      setMensagem('Erro ao atualizar processo. Tente novamente.');
      toast({
        title: "Erro.",
        description: "Erro ao atualizar o processo. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  return (
    <Box p={5} maxW="600px" mx="auto">
      <Heading as="h2" size="lg" mb={5} textAlign="center">
        Buscar Processo por Ano do Veículo
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl id="anoVeiculo">
          <FormLabel>Ano do Veículo</FormLabel>
          <Input
            type="text"
            placeholder="Digite o ano do veículo"
            value={anoVeiculo}
            onChange={(e) => setAnoVeiculo(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={buscarProcesso}>
          Buscar Processo
        </Button>
      </VStack>

      {processo && (
        <Box mt={10} p={5} borderWidth={1} borderRadius="lg" shadow="md">
          <Heading as="h3" size="md" mb={5}>
            Processo Encontrado: {processo.processo}
          </Heading>
          <VStack spacing={4} align="stretch">
            <FormControl id="valorEntrada">
              <FormLabel>Valor de Entrada</FormLabel>
              <Input
                type="text"
                value={valorEntrada}
                onChange={(e) => setValorEntrada(e.target.value)}
              />
            </FormControl>
            <FormControl id="parcelasPagas">
              <FormLabel>Parcelas Pagas</FormLabel>
              <Input
                type="text"
                value={parcelasPagas}
                onChange={(e) => setParcelasPagas(e.target.value)}
              />
            </FormControl>
            <FormControl id="valorParcela">
              <FormLabel>Valor da Parcela</FormLabel>
              <Input
                type="text"
                value={valorParcela}
                onChange={(e) => setValorParcela(e.target.value)}
              />
            </FormControl>
            <FormControl id="documentacao">
              <FormLabel>Documentação</FormLabel>
              <Input
                type="text"
                value={documentacao}
                onChange={(e) => setDocumentacao(e.target.value)}
              />
            </FormControl>
            <FormControl id="quantidadeAtraso">
              <FormLabel>Quantidade de Atraso</FormLabel>
              <Input
                type="text"
                value={quantidadeAtraso}
                onChange={(e) => setQuantidadeAtraso(e.target.value)}
              />
            </FormControl>
            <FormControl id="fipe">
              <FormLabel>Fipe</FormLabel>
              <Input
                type="text"
                value={fipe}
                onChange={(e) => setFipe(e.target.value)}
              />
            </FormControl>
            <FormControl id="banco">
              <FormLabel>Banco</FormLabel>
              <Input
                type="text"
                value={banco}
                onChange={(e) => setBanco(e.target.value)}
              />
            </FormControl>
            <Button colorScheme="green" onClick={atualizarProcesso}>
              Atualizar Processo
            </Button>
          </VStack>
        </Box>
      )}

      {mensagem && (
        <Text mt={5} color="red.500" textAlign="center">
          {mensagem}
        </Text>
      )}
    </Box>
  );
};

export default ProcessoForm;
