import React from 'react';
import ProcessoForm from './ProcessoForm';

function App() {
  return (
    <div className="App">
      <h1>Preenchimento de Processos</h1>
      <ProcessoForm />
    </div>
  );
}

export default App;
